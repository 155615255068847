@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@paid-ui/ui/combobox/Combobox.css';
@import '@paid-ui/ui/place-autocomplete/PlaceAutocomplete.css';
@import '@paid-ui/ui/rc-input-number/RcInputNumber.css';
@import '@paid-ui/components/contract-thumbnail/mba/Thumbnail.css';
@import '@paid-ui/components/contract-thumbnail/abic/Thumbnail.css';
@import '@paid-ui/components/contract-thumbnail/paid/Thumbnail.css';
@import '@paid-ui/components/contract-thumbnail/rbn/Thumbnail.css';
@import '@paid-ui/components/contract-thumbnail/aia/Thumbnail.css';

@layer base {
  @font-face {
    font-family: signpainter;
    font-style: normal;
    font-weight: 550;
    src:
      local('SignPainter'),
      url(/fonts/sign-painter.woff) format('woff');
  }

  :root {
    --font-sign: 'signpainter', sans-serif;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    margin-left: 8px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }

  #primer-cardnumber-field,
  #primer-expirydate-field,
  #primer-cvv-field {
    height: 100% !important;
  }
}

@layer components {
  .table {
    @apply w-full border-separate border-spacing-0 rounded-[10px] border border-grey-mid bg-white text-left shadow-down;
  }

  .row {
    @apply hover:bg-grey-lightest;
  }

  .th {
    @apply border-l border-grey-mid bg-grey-light px-4 py-3 text-sm font-medium leading-[18px] first:rounded-tl-[10px] first:border-l-0 last:rounded-tr-[10px];
  }

  .cell {
    @apply border-l border-t border-grey-mid px-4 py-3 text-sm leading-[18px] first:border-l-0;
  }

  .checkbox {
    @apply flex items-center justify-between gap-x-2;

    &::after {
      @apply h-4 w-4 rounded-sm border border-grey-tertiary bg-white content-[''];
    }
  }
}

.prose-clauses {
  --level-2-indent: 2.75em;
  --level-3-indent: 3.5em;
  --level-4-indent: 2em;
  --level-5-indent: 2em;

  ol {
    @apply relative m-0 list-none p-0 pl-[2em];

    /** For alternate clauses debug only */
    /* & [data-option-pattern] {
      @apply relative underline underline-offset-4 decoration-2 decoration-dotted decoration-pink bg-pink/5 cursor-help before:content-[attr(data-option-label)] before:py-1.5 before:px-2 before:bg-black/75 before:border before:hidden before:border-grey-mid before:shadow before:z-1000 before:absolute before:left-0 before:bottom-0 before:text-nowrap before:text-white before:text-sm before:backdrop-blur-sm before:rounded-md before:transform before:translate-y-10 hover:before:inline-block;
    } */

    & dl {
      & > dt {
        @apply scroll-mt-[125px] font-medium;
        & em {
          @apply font-normal not-italic;
        }
      }
      & > dd {
        @apply mt-[0.125em];
      }
    }

    /** Level 1 Heading */
    &[data-level='1'] {
      @apply pl-0 text-lg font-medium text-grey-tertiary;
      & > li {
        @apply mt-[1.5em] first:m-0;
        &::before {
          @apply pr-[0.2em] tabular-nums;
          content: var(--section-label, 'Section') ' ' attr(data-display-number) ' ·';
        }
        &:has(> span[role='heading']) {
          @apply flex flex-wrap;
          & > span,
          &::before {
            @apply sticky top-[65px] z-10 -my-[0.25em] bg-grey-lightest py-[0.25em] leading-8;
          }
          & > span {
            @apply flex-1;
          }
          &::before {
            @apply tabular-nums;
          }
          & > ol,
          & > ul,
          & > p,
          & > div,
          & > table {
            @apply basis-full;
          }

          & p,
          & table {
            @apply my-[0.5em] text-sm font-normal text-grey-dark;
          }
        }
      }
    }

    /** Level 2 Heading */
    &[data-level='2'],
    &[data-level='cs2'] {
      @apply text-base font-normal text-black;
      padding-left: var(--level-2-indent, 2em);
      & li {
        @apply my-[1em] scroll-mt-[125px];
        &::before {
          @apply absolute left-0 tabular-nums;
          content: attr(data-display-number);
        }
        & p,
        & table {
          @apply my-[0.5em] text-sm font-normal text-grey-dark;
        }
      }
    }

    /** Level 3 Heading */
    &[data-level='3'] {
      @apply text-sm font-normal text-grey-dark;
      padding-left: var(--level-3-indent, 2em);
      & li {
        @apply my-[0.5em] scroll-mt-[115px];
        &::before {
          @apply absolute left-0 tabular-nums;
          content: attr(data-display-number);
        }
      }
      & p,
      & table {
        @apply my-[0.25em];
      }
    }

    /** Level 4 Heading */
    &[data-level='4'] {
      @apply text-sm font-normal text-grey-dark;
      padding-left: var(--level-4-indent, 2em);
      & li {
        @apply my-[0.25em];
        &::before {
          @apply absolute left-0 tabular-nums;
          content: attr(data-display-number);
        }
      }
    }

    /** Level 5 Heading */
    &[data-level='5'] {
      @apply text-sm font-normal text-grey-dark;
      padding-left: var(--level-5-indent, 2em);
      & li {
        &::before {
          @apply absolute left-0 tabular-nums;
          content: attr(data-display-number);
        }
      }
    }

    &[data-level] {
      & > li.definitions > ol {
        @apply pl-0;
        & > li::before {
          @apply hidden;
        }
      }
    }
  }

  &.prose-tabs ol {
    & dl > dt {
      @apply scroll-mt-[200px];
    }
    &[data-level='1'] {
      & > li {
        &:has(> span[role='heading']) {
          & > span,
          &::before {
            @apply top-[140px];
          }
        }
      }
    }
    &[data-level='2'] > li {
      @apply scroll-mt-[200px];
    }
    &[data-level='3'] > li {
      @apply scroll-mt-48;
    }
    &[data-level='4'] > li {
      @apply scroll-mt-44;
    }
    &[data-level='5'] > li {
      @apply scroll-mt-44;
    }
  }
}

.prose-guide {
  ol {
    /** Level 1 Heading */
    &[data-level='1'] {
      @apply pl-0 text-lg font-medium text-grey-tertiary;
      & > li {
        @apply mt-[1.5em] flex flex-wrap first:m-0;
        &::before {
          @apply pr-[0.2em] tabular-nums;
          content: var(--section-label, 'Section') ' ' attr(data-display-number) ' ·';
        }
        & > p:first-child,
        &::before {
          @apply sticky top-[140px] z-10 -my-[0.25em] bg-grey-lightest py-[0.25em] leading-8;
        }
        & > p:first-child {
          @apply flex-1;
        }
        & > h4 {
          @apply mb-0 mt-[1em] basis-full text-base font-normal text-black;
        }
        & > :not(:first-child):not(h4) {
          @apply my-[0.5em] basis-full text-sm font-normal text-grey-dark;
        }
      }
    }
  }
}
